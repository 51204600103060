<template>
  <LoadingDialog v-if="loading" />
  <v-layout class="mt-3" align-center justify-center v-else>
    <v-flex xs12 sm8 md4>
      <AddToCalendarCard
        :showInterviewDetail="true"
        :interview="interview"
        :scheduledSlot="scheduledSlot"
      />
    </v-flex>
  </v-layout>
</template>

<script>
// import * as Sentry from '@sentry/browser'
import LoadingDialog from '@/components/LoadingDialog'
import { getScheduledSlot } from '@/helpers/firebase-functions'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'
import AddToCalendarCard from '@/components/AddToCalendarCard'

export default {
  components: {
    LoadingDialog,
    AddToCalendarCard
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      scheduledSlot: {
        isoDate: null,
        startTime: null,
        companyNameJa: null,
        applicantName: null
      },
      interview: {
        isCancelled: null, canclledBy: null
      },
      loading: true,
      text: {},
      items: [{
        title: 'm.addToCalendar.googleCalendar',
        icon: '/images/logos/google_calendar.png',
        link: 'googleCalendar'
      }, {
        title: 'm.addToCalendar.outlookCalendar',
        icon: '/images/logos/outlook_calendar.png',
        link: 'outlookCalendar',
        ics: true
      }, {
        title: 'm.addToCalendar.yahooCalendar',
        icon: '/images/logos/yahoo.png',
        link: 'yahooCalendar'
      }, {
        title: 'm.addToCalendar.appleCalendar',
        icon: '/images/logos/apple_calendar.png',
        link: 'appleCalendar',
        ics: true
      }]
    }
  },
  methods: {
    async loadData (initialConfirm) {
      this.loading = true
      const decodedToken = decodeURIComponent(this.token)
      const { data: { interview, scheduledSlot } } = await getScheduledSlot({
        cancelToken: decodedToken
      })

      this.interview = interview
      this.scheduledSlot = scheduledSlot
      this.loading = false
    }
  },
  computed: {},
  async mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_ADD_TO_CALENDAR)
    this.loadData()
  }
}
</script>

<style>
</style>
